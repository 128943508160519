import React from "react";
import { IComponentProp } from "@components/types";
import { CTEAM_ITEM_DATASOURCE } from "@constants";
import { MemberDisplayBox } from "@components";

export const MemberDisplayBoxWrapper: React.FC<IComponentProp> = () => {
    return (
        <div className="desktop:flex desktop:flex-row desktop:w-full desktop:items-center desktop:justify-center">
            {CTEAM_ITEM_DATASOURCE.map((member, index) => (
                <MemberDisplayBox
                    className="desktop:mx-14 mobile:my-2 tablet:my-2"
                    key={index}
                    title={member.title}
                    src={member.src}
                />
            ))}
        </div>
    );
};
