import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, About, Team, Project, Community } from "@pages";
import { Routes as RouteConfig } from "@routes";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={RouteConfig.home} element={<Home />} />
                <Route path={RouteConfig.about} element={<About />} />
                <Route path={RouteConfig.team} element={<Team />} />
                <Route path={RouteConfig.project} element={<Project />} />
                <Route path={RouteConfig.community} element={<Community />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
