import React from "react";
import {
    Page,
    CommonPageLayout,
    FoldImage,
    CommunityTabWrapper,
    MainLogoButtonWrapper,
    SocialIconWrapper,
} from "@components";
import { getCurrentPath } from "@utils/router";
import { getDevice } from "@utils/screen";
import { EDEVICE_TYPE } from "@enums";

export const Community = () => {
    const device = getDevice();

    return (
        <Page
            src="/images/bg-grid.png"
            direction="row"
            className="mobile:h-dvh tablet:h-dvh"
        >
            <MainLogoButtonWrapper />
            <CommonPageLayout
                className="flex flex-col desktop:justify-between desktop:pb-[10vh] mobile:h-dvh tablet:h-dvh"
                fitToViewport
            >
                <FoldImage text={getCurrentPath()} />
                <div className="desktop:mt-5% mobile:mt-[20dvh] tablet:mt-[20dvh] desktop:ml-[5%]">
                    <div className="text-white text-7xl tracking-wide tablet:text-8xl tablet:tracking-[0.4rem] desktop:mb-4 mobile:mb-[3dvh] tablet:mb-[3dvh]">
                        FOLLOW
                    </div>
                    <div className="text-white text-7xl tablet:text-8xl tablet:tracking-[0.4rem]">
                        US ON
                    </div>
                </div>
                {device === EDEVICE_TYPE.DESKTOP ? (
                    <CommunityTabWrapper />
                ) : (
                    <SocialIconWrapper />
                )}
            </CommonPageLayout>
        </Page>
    );
};
