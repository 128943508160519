import { useMediaQuery } from "react-responsive";
import { EDEVICE_TYPE } from "@enums";

export const getDevice = ():
    | (typeof EDEVICE_TYPE)[keyof typeof EDEVICE_TYPE]
    | undefined => {
    const isDesktop = useMediaQuery({
        query: `all and (min-width: 1600px)`,
    });

    const isTablet = useMediaQuery({
        query: `all and (min-width: 1000px) and (max-width: 1599px)`,
    });

    const isMobile = useMediaQuery({
        query: `all and (max-width: 999px)`,
    });

    if (isDesktop) {
        return EDEVICE_TYPE.DESKTOP;
    }
    if (isTablet) {
        return EDEVICE_TYPE.TABLET;
    }
    if (isMobile) {
        return EDEVICE_TYPE.MOBILE;
    }

    return undefined;
};
