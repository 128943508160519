import { CCOMMUNITY_LINK_DATASOURCE } from "@constants";
import React from "react";
import { SocialIcon } from "@components";

export const SocialIconWrapper = () => {
    return (
        <div className="flex flex-col items-end mr-10">
            {CCOMMUNITY_LINK_DATASOURCE.map(
                (nav, index) =>
                    nav.src && (
                        <div className="my-3 mobile:w-10 tablet:w-14">
                            <SocialIcon src={nav.src} key={index} to={nav.to} />
                        </div>
                    ),
            )}
        </div>
    );
};
