import React from "react";
import { IImageComponentProp } from "@components/types/image";
import { twMerge } from "tailwind-merge";

export const BackgroundImage: React.FC<IImageComponentProp> = ({
    src,
    color,
    children,
    className,
}) => {
    const classNames = twMerge("bg-contain bg-repeat h-full", className);
    return (
        <div
            className={classNames}
            style={
                src
                    ? { backgroundImage: `url(${src})` }
                    : { backgroundColor: color ?? "black" }
            }
        >
            {children}
        </div>
    );
};
