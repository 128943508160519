export const Routes = {
    home: "/",
    about: "/about",
    team: "/team",
    project: "/project",
    community: "/community",
};

export const ExternalRoutes = {
    twitter: "https://x.com/museion_social",
    discord: "https://discord.com/invite/Z2MTdU6jyb",
    instagram: "https://www.instagram.com/museion_social/",
    telegram: "https://t.me/museion_digital",
    medium: "https://medium.com/@social_museion",
};
