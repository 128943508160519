import React, { useEffect, useState } from "react";
import { IImageSizableProp } from "@components/types/image";

export const MainLogoWrapper: React.FC<
    IImageSizableProp & {
        isTriggered: boolean;
        separatorSrc: string;
    } & Partial<{
            delay?: number;
        }>
> = ({
    src,
    width,
    height,
    className,
    isTriggered,
    separatorSrc,
    delay = 0,
}) => {
    const [animationClass, setAnimationClass] = useState<string>();
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isTriggered) {
            timer = setTimeout(() => {
                setAnimationClass("animate-verticalWipeTopToBottom");
            }, delay);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isTriggered]);

    return (
        <div className="w-dvw flex flex-col items-center relative">
            <img
                src={separatorSrc}
                width={width}
                height={height}
                alt={src}
                className={`absolute top-0 invisible ${animationClass}`}
            />
            <img
                src={src}
                width={width}
                height={height}
                alt={src}
                className={className}
            />
        </div>
    );
};
