import React from "react";
import {
    Page,
    CommonPageLayout,
    FoldImage,
    MainLogoButtonWrapper,
    SwiperLooper,
} from "@components";
import { CPROJECT_ITEM_DATASOURCE } from "@constants";
import { getCurrentPath } from "@utils/router";

export const Project = () => {
    return (
        <Page
            src="/images/bg-grid.png"
            direction="row"
            className="overflow-y-hidden mobile:h-dvh tablet:h-dvh"
        >
            <MainLogoButtonWrapper />
            <CommonPageLayout
                className="flex items-center mobile:h-dvh tablet:h-dvh"
                fitToViewport
            >
                <FoldImage text={getCurrentPath()} />
                <SwiperLooper
                    datasource={CPROJECT_ITEM_DATASOURCE}
                    speed={10000}
                />
            </CommonPageLayout>
        </Page>
    );
};
