import React from "react";
import { twMerge } from "tailwind-merge";
import { IProjectCarouselItem } from "@components/types/looper";

export const ProjectCarouselItem: React.FC<IProjectCarouselItem> = ({
    className,
    src,
    title,
    owner,
    fitToContainer,
}) => {
    const classNames = twMerge(
        "flex flex-col overflow-x-hidden p-4",
        className,
    );
    return (
        <div className={classNames}>
            <div className="desktop:w-32 mobile:w-40 tablet:w-40 desktop:h-32 mobile:h-40 tablet:h-40 border border-white flex justify-center items-center shadow-[0_0_10px_2px_#fff]">
                <div
                    className="bg-contain bg-no-repeat bg-center"
                    style={{
                        backgroundImage: `url(${src})`,
                        width: fitToContainer ? "100%" : "70%",
                        height: fitToContainer ? "100%" : "70%",
                    }}
                />
            </div>
            <div className="desktop:w-32 mobile:w-40 tablet:w-40 flex justify-start mt-4 text-white text-[0.7rem] font-[AppleSDGothicNeoL]">
                <span className="mr-4">#</span>
                <span>{`${title} / ${owner}`}</span>
            </div>
        </div>
    );
};
