import React, { useEffect, useState } from "react";
import { FolderButton } from "@components";
import { CCOMMUNITY_LINK_DATASOURCE } from "@constants";
import { debounce } from "lodash";

export const CommunityTabWrapper: React.FC = () => {
    const OFFSET_WIDTH = 50;
    const [hoverChildId, setHoverChildId] = useState<number | null>(null);
    const [, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, 500);
    const handleMouseEnter = (childId: number | null) => {
        setHoverChildId(childId);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            {CCOMMUNITY_LINK_DATASOURCE.map((nav, index) => (
                <div
                    className="desktop:mt-4 desktop:border-b-4 desktop:border-white"
                    key={index}
                >
                    <FolderButton
                        src="/images/folder-button.png"
                        setHoveredId={handleMouseEnter}
                        isHovered={hoverChildId === index}
                        id={index}
                        title={nav.title}
                        to={nav.to}
                        width={window.innerWidth / 3 - OFFSET_WIDTH}
                    />
                </div>
            ))}
        </div>
    );
};
