import { INavigationButtonAttributes } from "@components/types/button";
import { ExternalRoutes } from "@routes";

export const CCOMMUNITY_LINK_DATASOURCE: (INavigationButtonAttributes & {
    src?: string;
})[] = [
    {
        title: "TWITTER",
        to: ExternalRoutes.twitter,
        src: "/images/x-icon.png",
    },
    {
        title: "DISCORD",
        to: ExternalRoutes.discord,
        src: "/images/discord-icon.png",
    },
    {
        title: "INSTAGRAM",
        to: ExternalRoutes.instagram,
        src: "/images/instagram-icon.png",
    },
    {
        title: "TELEGRAM",
        to: ExternalRoutes.telegram,
        src: "/images/telegram-icon.png",
    },
    {
        title: "MEDIUM",
        to: ExternalRoutes.medium,
        src: "/images/medium-icon.png",
    },
];
