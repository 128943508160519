import React, { MouseEvent, useEffect, useState } from "react";
import {
    Page,
    MainLogoWrapper,
    MainSloganWrapper,
    MainLogoButtonWrapper,
} from "@components";
import { getDevice } from "@utils/screen";
import { IComponentProp } from "@components/types";
import { EDEVICE_TYPE } from "@enums";
import { debounce } from "lodash";

const PageLayout: React.FC<IComponentProp> = ({ className, children }) => {
    return <div className={className}>{children}</div>;
};

export const Home = () => {
    const TRIGGER_TIME_MS = 1200;
    const [triggerState, setTriggerState] = useState(false);
    const [sloganAnimationClass, setSloganAnimationClass] =
        useState("invisible");
    const [, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, 500);
    const device = getDevice();
    const handleMobileEntry = (e: MouseEvent) => {
        e.stopPropagation();
        setTriggerState(true);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        let triggerTimer: NodeJS.Timeout;
        if (device === EDEVICE_TYPE.DESKTOP) {
            triggerTimer = setTimeout(() => {
                setTriggerState(true);
            }, TRIGGER_TIME_MS);
        }

        return () => {
            if (triggerTimer) {
                clearTimeout(triggerTimer);
            }
        };
    }, [device]);

    useEffect(() => {
        if (triggerState) {
            const animationSetTimer = setTimeout(
                () => {
                    setSloganAnimationClass("visible animate-verticalScaleUp");
                },
                device === EDEVICE_TYPE.DESKTOP
                    ? TRIGGER_TIME_MS - 400
                    : TRIGGER_TIME_MS + 800,
            );

            return () => {
                clearTimeout(animationSetTimer);
            };
        }
        return undefined;
    }, [triggerState, device]);

    return (
        <div className="w-full flex flex-col justify-center relative">
            <Page
                src="/images/bg-grid.png"
                direction="column"
                initialized={device !== EDEVICE_TYPE.DESKTOP && triggerState}
                className="overflow-hidden mobile:h-dvh tablet:h-dvh"
            >
                {device !== EDEVICE_TYPE.DESKTOP && !triggerState ? (
                    <button
                        type="button"
                        className="h-dvh"
                        onClick={handleMobileEntry}
                    >
                        <MainLogoButtonWrapper className="mobile:top-1/2 tablet:top-1/2 mobile:left-1/2 tablet:left-1/2 mobile:-translate-x-1/2 tablet:-translate-x-1/2 mobile:-translate-y-1/2 tablet:-translate-y-1/2 mobile:w-[25dvw] tablet:w-[14dvw]" />
                    </button>
                ) : (
                    <PageLayout className="flex flex-col h-screen desktop:justify-between mobile:justify-center tablet:justify-center mobile:animate-fadeIn tablet:animate-fadeIn mobile:opacity-0 tablet:opacity-0">
                        <MainLogoButtonWrapper />
                        <div className="flex-1 desktop:mt-12.5% mobile:mt-[42dvh] tablet:mt-[33dvh]">
                            <MainLogoWrapper
                                src="/images/main-logo-before.svg"
                                separatorSrc="/images/main-logo-after-separator.svg"
                                delay={
                                    device === EDEVICE_TYPE.DESKTOP ? 0 : 1000
                                }
                                isTriggered={triggerState}
                                width={
                                    device === EDEVICE_TYPE.DESKTOP
                                        ? 900
                                        : window.innerWidth
                                }
                                height={100}
                            />
                            <MainSloganWrapper
                                className={sloganAnimationClass}
                                speed={device === EDEVICE_TYPE.DESKTOP ? 2 : 4}
                            />
                        </div>
                    </PageLayout>
                )}
            </Page>
        </div>
    );
};
