import React from "react";
import {
    Page,
    CommonPageLayout,
    FoldImage,
    MainLogoButtonWrapper,
    MemberDisplayBoxWrapper,
} from "@components";
import { getCurrentPath } from "@utils/router";

export const Team = () => {
    return (
        <Page
            src="/images/bg-grid.png"
            direction="row"
            className="overflow-y-hidden mobile:h-dvh tablet:h-dvh"
        >
            <FoldImage text={getCurrentPath()} />
            <MainLogoButtonWrapper />
            <CommonPageLayout
                className="flex mobile:items-center tablet:items-center mobile:justify-center tablet:justify-center mobile:h-dvh tablet:h-dvh"
                fitToViewport
            >
                <MemberDisplayBoxWrapper />
            </CommonPageLayout>
        </Page>
    );
};
