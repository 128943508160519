import { useLocation } from "react-router-dom";
import { Routes as RouteConfig } from "@routes";

export const getCurrentPath = (capitalize: boolean = true) => {
    const { pathname } = useLocation();
    const rootPath = pathname.split("/")[1];
    return capitalize ? rootPath.toUpperCase() : rootPath;
};

export const getIsInternalRoute = (to: string | undefined) => {
    return Object.values(RouteConfig).find(v => v === to);
};
