import React from "react";
import { IImageComponentProp } from "@components/types/image";

export const FoldImage: React.FC<IImageComponentProp & { text: string }> = ({
    text,
}) => {
    const src = "/images/fold.svg";
    return (
        <div className="absolute top-0 right-0 overflow-x-hidden mobile:hidden tablet:hidden">
            <div className="relative">
                <span className="absolute text-black right-[3.5rem] top-5 text-2xl">
                    {text}
                </span>
                <img src={src} alt={src} />
            </div>
        </div>
    );
};
