import React from "react";
import { MainLogoButton } from "@components";
import { CLAYOUT_MAIN_LOGO_BUTTON_OFFSET } from "@constants";
import { IComponentProp } from "@components/types";
import { twMerge } from "tailwind-merge";

export const MainLogoButtonWrapper: React.FC<IComponentProp> = ({
    className,
}) => {
    const classNames = twMerge(
        `${CLAYOUT_MAIN_LOGO_BUTTON_OFFSET.DESKTOP} ${CLAYOUT_MAIN_LOGO_BUTTON_OFFSET.MOBILE} ${CLAYOUT_MAIN_LOGO_BUTTON_OFFSET.TABLET}`,
        className,
    );
    return (
        <div className={classNames}>
            <MainLogoButton />
        </div>
    );
};
