import React from "react";
import { IButtonProps } from "@components/types/button";

export const NavigationFolderTitleInActive: React.FC<IButtonProps> = ({
    className,
    width,
    height,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 324.14 61.52"
            className={className}
            width={width}
            height={height}
        >
            <g id="_레이어_1-2" data-name="레이어 1">
                <polygon points="66.15 .5 1.27 61.02 323.64 61.02 323.64 .5 66.15 .5" />
            </g>
        </svg>
    );
};
