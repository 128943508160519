import React, { MouseEvent, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { IComponentProp } from "@components/types";
import { ProjectCarouselItem } from "@components";
import {
    CBREAK_POINT_DESKTOP,
    CBREAK_POINT_SMALL_TABLET,
    CBREAK_POINT_LARGE_TABLET,
    CBREAK_POINT_LARGE_MOBILE,
    CBREAK_POINT_MEDIUM_TABLET,
    CBREAK_POINT_SMALL_MOBILE,
    CBREAK_POINT_MEDIUM_MOBILE,
} from "@constants";
import { debounce } from "lodash";
import { IProjectCarouselItem } from "@components/types/looper";
import { getDevice } from "@utils/screen";
import { EDEVICE_TYPE } from "@enums";

export const SwiperLooper: React.FC<
    IComponentProp & {
        datasource: IProjectCarouselItem[];
        speed: number;
    }
> = ({ datasource, speed }) => {
    const device = getDevice();
    const [activeSlideIndex, setActiveSlideIndex] = useState<number | null>(
        null,
    );
    const [, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, 500);

    const handleSwiperMouseEnter = (e: MouseEvent) => {
        const targetElement = e.target as HTMLElement;
        const hoveredIndex = Number(
            targetElement.offsetParent?.getAttribute("data-swiper-slide-index"),
        );
        setActiveSlideIndex(hoveredIndex);
    };

    const handleSwiperClicked = (swiper: SwiperClass) => {
        const clickedIndex = Number(
            swiper.clickedSlide?.dataset?.swiperSlideIndex,
        );
        if (typeof clickedIndex === "number" && clickedIndex >= 0) {
            setActiveSlideIndex(prev => {
                if (prev === clickedIndex && datasource[clickedIndex].to) {
                    window.open(datasource[clickedIndex].to, "_blank");
                }
                return clickedIndex;
            });
        } else {
            setActiveSlideIndex(null);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // TODO: Pagniation bar is too slow after increasing swiper sliders.
    return (
        <Swiper
            enabled
            className="animate-slide flex flex-col items-center"
            autoplay={{
                delay: 0,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            pagination={{
                enabled: true,
                clickable: true,
                type: "progressbar",
                progressbarOpposite: false,
                renderProgressbar: progressbarFillClass =>
                    `<div class="relative bg-black">
                        <div
                            role="slider"
                            class="${progressbarFillClass}"
                        />
                    </div>`,
            }}
            breakpoints={{
                [`${CBREAK_POINT_DESKTOP}`]: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                    initialSlide: 2,
                },
                [`${CBREAK_POINT_LARGE_TABLET}`]: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                    initialSlide: 1,
                },
                [`${CBREAK_POINT_MEDIUM_TABLET}`]: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                    initialSlide: 1,
                },
                [`${CBREAK_POINT_SMALL_TABLET}`]: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                    initialSlide: 1,
                },
                [`${CBREAK_POINT_LARGE_MOBILE}`]: {
                    slidesPerView: 2.5,
                    spaceBetween: 5,
                    initialSlide: 0,
                },
                [`${CBREAK_POINT_MEDIUM_MOBILE}`]: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                    initialSlide: 0,
                },
                [`${CBREAK_POINT_SMALL_MOBILE}`]: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                    initialSlide: 0,
                },
            }}
            speed={speed}
            direction="horizontal"
            slidesPerView={1.5}
            centeredSlides
            grabCursor
            spaceBetween={5}
            width={window.innerWidth}
            shortSwipes
            loop
            onClick={handleSwiperClicked}
        >
            {datasource.map((d, index) => (
                <SwiperSlide
                    key={index}
                    onMouseEnter={
                        device === EDEVICE_TYPE.DESKTOP
                            ? handleSwiperMouseEnter
                            : undefined
                    }
                >
                    <ProjectCarouselItem
                        fitToContainer={d.fitToContainer}
                        title={`${d.title}`}
                        src={d.src}
                        owner={d.owner}
                    />
                </SwiperSlide>
            ))}
            <div className="h-28 desktop:h-40 flex items-end">
                <div className="flex justify-center text-white text-center desktop:text-[1.2rem] mobile:text-[0.8rem] tablet:text-[1.1rem]">
                    {typeof activeSlideIndex === "number" &&
                        activeSlideIndex >= 0 && (
                            <a
                                href={datasource[activeSlideIndex].to}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>
                                    {datasource[activeSlideIndex].description}
                                </span>
                            </a>
                        )}
                </div>
            </div>
        </Swiper>
    );
};
