export const CTEAM_ITEM_DATASOURCE: { title: string; src: string }[] = [
    {
        title: "A",
        src: "/images/member-sample-a.png",
    },
    {
        title: "B",
        src: "/images/member-sample-b.png",
    },
    {
        title: "C",
        src: "/images/member-sample-c.png",
    },
    {
        title: "D",
        src: "/images/member-sample-d.png",
    },
    {
        title: "E",
        src: "/images/member-sample-e.png",
    },
    {
        title: "F",
        src: "/images/member-sample-f.png",
    },
];
