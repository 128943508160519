import React, { forwardRef, Ref } from "react";
import { IComponentProp } from "@components/types";
import {
    CLAYOUT_HEIGHT_CONTENT_DESKTOP,
    CLAYOUT_TOP_MARGIN_OFFSET_DESKTOP,
    CLAYOUT_LEFT_MARGIN_CONTENT_DESKTOP,
} from "@constants";
import { ICommonPageLayoutProp } from "@components/types/layout";
import { twMerge } from "tailwind-merge";

export const CommonPageLayout = forwardRef<
    HTMLDivElement,
    IComponentProp & ICommonPageLayoutProp
>(
    (
        { className, children, fitToViewport, scrollable },
        ref: Ref<HTMLDivElement>,
    ) => {
        const classNames = twMerge(
            "desktop:border-t desktop:border-l desktop:border-white h-full",
            `${scrollable ? `desktop:overflow-y-scroll ${CLAYOUT_HEIGHT_CONTENT_DESKTOP}` : ""}`,
            className,
        );
        return (
            <div
                className={`
                ${CLAYOUT_TOP_MARGIN_OFFSET_DESKTOP} ${CLAYOUT_LEFT_MARGIN_CONTENT_DESKTOP}
                ${fitToViewport ? CLAYOUT_HEIGHT_CONTENT_DESKTOP : "desktop:h-full"}
                w-full overflow-x-hidden overflow-y-hidden
            `}
            >
                <div className={classNames} ref={ref}>
                    {children}
                </div>
            </div>
        );
    },
);
