import React from "react";
import {
    INavigationActionHandler,
    INavigationButtonProps,
} from "@components/types/button";
import {
    NavigationFolderDescription,
    NavigationFolderTitleInActive,
    NavigationFolderTitleActive,
} from "@components";
import { twMerge } from "tailwind-merge";

export const NavigationButton: React.FC<
    Omit<INavigationButtonProps, "to"> & INavigationActionHandler
> = ({
    className,
    width,
    height,
    title,
    description,
    setHoveredId,
    id,
    isHovered,
}) => {
    const classNames = twMerge(
        "relative cursor-pointer scale-[1.68] origin-bottom-right z-10",
        className,
    );
    const handleEnter = () => {
        setHoveredId(id);
    };
    const handleLeave = () => {
        setHoveredId(null);
    };

    return isHovered ? (
        <div
            className={classNames}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            <span className="absolute top-[8%] right-4 text-black text-xl">
                {title}
            </span>
            <NavigationFolderTitleActive
                className="fill-white stroke-white"
                width={width}
                height={height}
            />
            <div className="absolute bottom-0">
                <span className="absolute top-[70%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[0.7rem]">
                    {description}
                </span>
                <NavigationFolderDescription
                    className="fill-black stroke-white stroke-1"
                    width={width}
                    height={height}
                />
            </div>
        </div>
    ) : (
        <div
            className="relative"
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            <span className="absolute top-1/2 left-1/2 text-white text-2xl -translate-x-1/3 -translate-y-1/3">
                {title}
            </span>
            <NavigationFolderTitleInActive
                className="fill-black stroke-white"
                width={width}
                height={height}
            />
        </div>
    );
};
