import React, { useCallback, useEffect, useRef, useState } from "react";

export const ComponentLooper: React.FC<{
    speed: number;
    direction: "right" | "left";
    children: React.ReactNode;
    play: boolean;
}> = ({ speed, direction, children, play }) => {
    const [looperInstances, setLooperInstances] = useState(1);
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const resetAnimation = () => {
        if (innerRef?.current) {
            innerRef.current.setAttribute("data-animate", "false");

            setTimeout(() => {
                if (innerRef?.current) {
                    innerRef.current.setAttribute("data-animate", "true");
                }
            }, 10);
        }
    };

    const setupInstances = useCallback(() => {
        if (!innerRef?.current || !outerRef?.current) return;

        const { width } = innerRef.current.getBoundingClientRect();
        const { width: parentWidth } = outerRef.current.getBoundingClientRect();
        const widthDeficit = parentWidth - width;
        const instanceWidth = width / innerRef.current.children.length;

        if (widthDeficit) {
            setLooperInstances(
                looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1,
            );
        }

        resetAnimation();
    }, [looperInstances]);

    useEffect(() => setupInstances(), [setupInstances]);
    useEffect(() => {
        window.addEventListener("resize", setupInstances);

        return () => {
            window.removeEventListener("resize", setupInstances);
        };
    }, [looperInstances, setupInstances]);

    return (
        <div className="w-full overflow-hidden" ref={outerRef}>
            <div
                className="flex justify-center w-fit group"
                data-pause={!play}
                data-animate="true"
                ref={innerRef}
            >
                {[...Array(looperInstances)].map((_, index) => (
                    <div
                        key={index + 1}
                        className="flex w-max animate-none group-data-[animate=true]:animate-slide group-data-[pause=true]:pause"
                        style={{
                            animationDuration: `${speed}s`,
                            animationDirection:
                                direction === "right" ? "reverse" : "normal",
                        }}
                    >
                        {children}
                    </div>
                ))}
            </div>
        </div>
    );
};
