import React from "react";

export const SocialIcon: React.FC<
    { src: string } & Partial<{ to?: string }>
> = ({ src, to }) => {
    return (
        <a href={to} className="inline-block" target="_blank" rel="noreferrer">
            <img src={src} alt={src} />
        </a>
    );
};
