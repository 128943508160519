import React from "react";
import { IButtonProps } from "@components/types/button";

export const NavigationFolderTitleActive: React.FC<IButtonProps> = ({
    className,
    width,
    height,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 548.84 223.8"
            className={className}
            width={width}
            height={height}
        >
            <g>
                <g>
                    <polygon points="548 .5 548 223.3 110.61 223.3 110.61 61.02 225.63 61.02 290.51 .5 548 .5" />
                </g>
            </g>
        </svg>
    );
};
