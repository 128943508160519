import React from "react";
import { Routes as RouteConfig } from "@routes";
import { Link } from "react-router-dom";

export const MainLogoButton: React.FC = () => {
    const src = "/images/main-symbol.svg";
    return (
        <Link to={RouteConfig.home}>
            <img src={src} alt={src} />
        </Link>
    );
};
