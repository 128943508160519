import React from "react";
import {
    INavigationActionHandler,
    INavigationButtonAttributes,
} from "@components/types/button";
import { IImageSizableProp } from "@components/types/image";
import { getIsInternalRoute } from "@utils/router";
import { Link } from "react-router-dom";
import { getDevice } from "@utils/screen";
import { twMerge } from "tailwind-merge";
import { EDEVICE_TYPE } from "@enums";

export const FolderButton: React.FC<
    INavigationButtonAttributes & IImageSizableProp & INavigationActionHandler
> = ({
    src,
    to,
    title,
    width,
    height,
    id,
    isHovered,
    setHoveredId,
    className,
}) => {
    const classNames = twMerge(
        "relative overflow-hidden",
        "mobile:border-b-4 mobile:border-white mobile:w-dvw mobile:flex mobile:justify-end",
        "tablet:border-b-4 tablet:border-white tablet:w-dvw tablet:flex tablet:justify-end",
        className,
    );
    const SIZE_OFFSET = 0.8;
    const device = getDevice();
    const handleEnter = () => {
        setHoveredId(id);
    };
    const handleLeave = () => {
        setHoveredId(null);
    };
    if (isHovered) {
        // eslint-disable-next-line no-param-reassign
        src = "/images/folder-hover-button.png";
    }
    return getIsInternalRoute(to) && device !== EDEVICE_TYPE.DESKTOP ? (
        to && (
            <Link to={to} className={classNames}>
                <span className="absolute text-black mobile:text-3xl tablet:text-3xl mobile:right-6 tablet:right-6 mobile:top-[15%] tablet:top-[15%]">
                    {title}
                </span>
                <img
                    src={src}
                    width={Number(width) * SIZE_OFFSET}
                    height={Number(height) * SIZE_OFFSET}
                    alt={src}
                />
            </Link>
        )
    ) : (
        <a
            href={to}
            className={classNames}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            target="_blank"
            rel="noreferrer"
        >
            <span
                className={`absolute text-black desktop:text-[3.5rem] desktop:left-12 desktop:top-1/2 desktop:-translate-y-[40%] ${isHovered ? "desktop:text-white" : "desktop:text-black"}`}
            >
                {title}
            </span>
            <img src={src} width={width} height={height} alt={src} />
        </a>
    );
};
