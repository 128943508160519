import React from "react";
import { IButtonProps } from "@components/types/button";

export const NavigationFolderDescription: React.FC<IButtonProps> = ({
    className,
    width,
    height,
}) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 548.84 223.8"
        >
            <g>
                <g>
                    <polygon points="547.99 223.3 82.47 223.3 .84 77.29 466.36 77.29 547.99 223.3" />
                </g>
            </g>
        </svg>
    );
};
