import React from "react";
import { IComponentProp } from "@components/types";
import { MainSlogan, ComponentLooper } from "@components";
import { twMerge } from "tailwind-merge";

export const MainSloganWrapper: React.FC<
    IComponentProp & Partial<{ speed: number }>
> = ({ className, speed = 2 }) => {
    const classNames = twMerge(
        "w-full flex items-center h-8 desktop:bg-white desktop:mt-20 mobile:mt-10 tablet:mt-16 mobile:text-white tablet:text-white",
        className,
    );
    return (
        <div className={classNames}>
            <ComponentLooper speed={speed} direction="left" play>
                <MainSlogan text="Museion the Web3 Brand House. Obsessed with Beauty." />
            </ComponentLooper>
        </div>
    );
};
