import React, { useEffect, useRef, useState } from "react";
import {
    MainLogoButtonWrapper,
    Page,
    FoldImage,
    CommonPageLayout,
    NavigationButtonWrapper,
} from "@components";
import {
    CLAYOUT_HEIGHT_CONTENT_DESKTOP,
    CLAYOUT_HEIGHT_CONTENT_MOBILE,
} from "@constants";
import { IComponentProp } from "@components/types";
import { getDevice } from "@utils/screen";
import { twMerge } from "tailwind-merge";
import { getCurrentPath } from "@utils/router";
import { debounce } from "lodash";
import { EDEVICE_TYPE } from "@enums";

const PageSeparator = () => {
    return (
        <div className="desktop:h-[10vh] mobile:h-[30lvh] tablet:h-[40lvh]" />
    );
};
const ViewportPageWrapper: React.FC<IComponentProp> = ({
    className,
    children,
}) => {
    const classNames = twMerge(
        `${CLAYOUT_HEIGHT_CONTENT_MOBILE} ${CLAYOUT_HEIGHT_CONTENT_DESKTOP} desktop:mx-[10%] mobile:mx-3 tablet:mx-3`,
        className,
    );
    return <div className={classNames}>{children}</div>;
};

export const About = () => {
    const device = getDevice();

    const containerRef = useRef<HTMLDivElement>(null);
    const [logoSrc, setLogoSrc] = useState<string>();
    const [showFooter, setShowFooter] = useState(
        device !== EDEVICE_TYPE.DESKTOP,
    );

    useEffect(() => {
        const container = containerRef?.current;

        const handleScroll = debounce(() => {
            if (device !== EDEVICE_TYPE.DESKTOP) {
                setShowFooter(true);
                return;
            }
            if (container) {
                const lastChild = container?.lastElementChild;
                const lastChildRect = lastChild?.getBoundingClientRect();
                if (lastChildRect) {
                    const isLastChildVisible =
                        lastChildRect.top >= 0 &&
                        Math.round(lastChildRect.bottom) <=
                            Math.round(
                                window.innerHeight ||
                                    document.documentElement.clientHeight,
                            );
                    setShowFooter(isLastChildVisible);
                }
            }
        }, 70);
        const handleSnap = debounce(() => {
            if (container) {
                const scrollPosition = container.scrollTop;
                const snapElement = container.querySelector(
                    ".snap-item",
                ) as HTMLElement;

                if (snapElement) {
                    const childHeight = snapElement.offsetHeight;
                    const nearestSnapPoint =
                        Math.round(scrollPosition / childHeight) * childHeight;
                    container.scrollTo({
                        top: nearestSnapPoint,
                        behavior: "smooth",
                    });
                }
            }
        }, 70);

        if (container) {
            if (device === EDEVICE_TYPE.DESKTOP) {
                container.addEventListener("scroll", handleScroll);
                container.addEventListener("scroll", handleSnap);
            } else {
                container.addEventListener("touchend", handleScroll);
                container.addEventListener("wheel", handleScroll);
            }
        }
        return () => {
            if (container) {
                if (device === EDEVICE_TYPE.DESKTOP) {
                    container.removeEventListener("scroll", handleScroll);
                    container.removeEventListener("scroll", handleSnap);
                } else {
                    container.removeEventListener("touchend", handleScroll);
                    container.removeEventListener("wheel", handleScroll);
                }
            }
        };
    }, [device]);

    useEffect(() => {
        if (device === EDEVICE_TYPE.DESKTOP) {
            setLogoSrc("/images/main-logo-after.svg");
        } else {
            setLogoSrc("/images/main-logo-before-mobile.png");
        }
    }, [device]);

    return (
        <div>
            <Page src="/images/bg-grid.png" direction="row">
                <FoldImage text={getCurrentPath()} />
                <MainLogoButtonWrapper />
                <CommonPageLayout
                    fitToViewport={false}
                    scrollable
                    ref={containerRef}
                >
                    <div
                        className={`text-white flex flex-col text-7xl snap-item ${CLAYOUT_HEIGHT_CONTENT_DESKTOP}`}
                    >
                        <div
                            className={`flex desktop:flex-col-reverse mobile:flex-col tablet:flex-col ${CLAYOUT_HEIGHT_CONTENT_DESKTOP} mobile:mx-3 tablet:mx-3`}
                        >
                            <div className="mobile:h-full tablet:h-full mobile:flex tablet:flex mobile:flex-col-reverse tablet:flex-col-reverse mobile:my-[2lvh] tablet:my-[2lvh]">
                                <img
                                    src={logoSrc}
                                    alt={logoSrc}
                                    width={100}
                                    height={100}
                                    className="desktop:w-full self-end"
                                />
                            </div>
                            <div className="pl-4 desktop:mb-[3%] mobile:text-xl tablet:text-xl mobile:flex tablet:flex mobile:flex-col tablet:flex-col">
                                <span className="flex flex-col mobile:items-end tablet:items-end desktop:gap-4 mobile:gap-2 tablet:gap-2">
                                    <span>Obsessed with Beauty</span>
                                    <span>Museion the Web3 Brand House.</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    {device !== EDEVICE_TYPE.DESKTOP && <PageSeparator />}
                    <ViewportPageWrapper className="flex desktop:flex-col-reverse mobile:flex-col tablet:flex-col justify-center snap-item">
                        <div className="text-white flex desktop:flex-row mobile:flex-col tablet:flex-col justify-between desktop:items-start mobile:items-end tablet:items-end desktop:gap-20 mobile:gap-10 tablet:gap-10">
                            <div className="text-4xl items-center flex flex-col desktop:w-1/2 desktop:h-full desktop:justify-center mobile:w-full tablet:w-full mobile:leading-none tablet:leading-none">
                                <span>ABOUT US</span>
                            </div>
                            <div className="mobile:flex tablet:flex mobile:flex-col tablet:flex-col mobile:items-center tablet:items-center desktop:w-1/2">
                                <p className="mobile:text-[0.7rem] tablet:text-[0.7rem] mobile:w-3/4 tablet:w-3/4 desktop:[letter-spacing:0.11rem] mobile:text-center tablet:text-center">
                                    Welcome to MUSEION
                                    <br />
                                    <br />
                                    MUSEION is the pioneer of the digital museum
                                    in the Web3 ecosystem that showcases
                                    outstanding artworks and technologies. We
                                    carry on the heritage of traditional museums
                                    by exhibiting humanity&apos;s marvelous
                                    culture and intellectual assets while
                                    building a community based on trust.
                                    <br />
                                    <br />
                                    Our goal is to cultivate a culture founded
                                    on trust and respect, where members can
                                    freely collaborate and share knowledge.
                                    MUSEION provides a platform that fosters
                                    creative exchanges through diverse
                                    experiences and activities.
                                    <br />
                                    <br />
                                    We are an open space for everyone, where
                                    artists, developers, researchers, and
                                    individual enthusiasts from all fields come
                                    together to promote new ideas and innovative
                                    projects. We lead the development of new
                                    content and technology in the Web3 ecosystem
                                    to contribute to humanity&apos;s cultural
                                    advancement.
                                    <br />
                                    <br />
                                    MUSEION is the museum for the new digital
                                    age. Join MUSEION in sharing experiences,
                                    cultivating new knowledge, and forging
                                    long-lasting friendships.
                                    <br />
                                    <br />
                                    Let&apos;s build a better future together.
                                </p>
                            </div>
                        </div>
                    </ViewportPageWrapper>
                    {device !== EDEVICE_TYPE.DESKTOP && <PageSeparator />}
                    <ViewportPageWrapper className="flex flex-col justify-center snap-item mobile:relative tablet:relative mobile:mx-0 tablet:mx-0 mobile:h-lvh tablet:h-lvh">
                        {device !== EDEVICE_TYPE.DESKTOP && (
                            <MainLogoButtonWrapper />
                        )}
                        <div className="text-white flex flex-col desktop:items-end mobile:items-center tablet:items-center desktop:text-4xl mobile:text-3xl tablet:text-3xl">
                            <div className="desktop:my-[2%] mobile:my-[5%] tablet:my-[2%]">
                                <a
                                    href="https://perpetual-firefly-a58.notion.site/PRIVACY-POLICY-3743ca61a579419abfbbb7fa6e4caa87?pvs=74"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    PRIVACY POLICY
                                </a>
                            </div>

                            <div className="desktop:my-[2%] mobile:my-[5%] tablet:my-[2%]">
                                <a
                                    href="https://perpetual-firefly-a58.notion.site/TERMS-OF-USE-75b13dba7ff74f9fb86766a8ed2a0478"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    TERMS OF USE
                                </a>
                            </div>
                        </div>
                        {showFooter && <NavigationButtonWrapper initialized />}
                    </ViewportPageWrapper>
                </CommonPageLayout>
            </Page>
        </div>
    );
};
