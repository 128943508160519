import { IProjectCarouselItem } from "@components/types/looper";

export const CBREAK_POINT_SMALL_MOBILE = 500;
export const CBREAK_POINT_MEDIUM_MOBILE = 550;
export const CBREAK_POINT_LARGE_MOBILE = 600;
export const CBREAK_POINT_SMALL_TABLET = 800;
export const CBREAK_POINT_MEDIUM_TABLET = 1100;
export const CBREAK_POINT_LARGE_TABLET = 1400;
export const CBREAK_POINT_DESKTOP = 1600;
export const CPROJECT_ITEM_DATASOURCE: IProjectCarouselItem[] = [
    {
        src: "/images/project-brand-launch.png",
        owner: "MUSEION",
        title: "Project 1",
        description: "Museion Brand Launch",
    },
    {
        src: "/images/project-musa-issuance.png",
        owner: "MUSEION",
        title: "Project 2",
        description: "$MUSA Issuance",
        to: "https://etherscan.io/token/0x486a3c5f34cdc4ef133f248f1c81168d78da52e8",
    },
    {
        src: "/images/project-musa-airdrop.png",
        owner: "MUSEION",
        title: "Project 3",
        description: "$MUSA airdrop",
        to: "https://medium.com/@social_museion/press-nft-curation-platform-museion-hosts-airdrop-event-in-celebration-of-launch-db2ef40bab5a",
    },
    {
        src: "/images/project-nft-marketplace-launch.png",
        owner: "MUSEION",
        title: "Project 4",
        description: "Artistic NFT Curation Marketplace Launch",
        to: "https://medium.com/@social_museion/press-museion-opens-nft-marketplace-we-will-serve-as-a-bridge-connecting-the-public-and-c03715212f30",
        fitToContainer: true,
    },
    {
        src: "/images/project-artist-collaboration-alberto.png",
        owner: "Alberto",
        title: "Project 5",
        description: "Artist Alberto Collaboration",
        to: "https://medium.com/@social_museion/press-museion-transforms-nft-curation-with-lee-jong-seok-alberto-collaboration-bridging-art-and-7bb69da7cfa9",
        fitToContainer: true,
    },
    {
        src: "/images/project-artist-collaboration-qhee.png",
        owner: "Qhee",
        title: "Project 6",
        description: "Beauty of Orient : KBW After Party with Artist Qhee Han",
        to: "https://medium.com/@social_museion/review-beauty-of-orient-hosted-by-museion-da8d58340683",
        fitToContainer: true,
    },
    {
        src: "/images/project-dune-dashboard.png",
        owner: "MUSEION",
        title: "Project 7",
        description: "Museion Dune",
        to: "https://dune.com/museion/museion",
    },
    {
        src: "/images/project-rebranding.png",
        owner: "MUSEION",
        title: "Project 8",
        description: "Museion Rebrand",
        fitToContainer: true,
    },
    // {
    //     src: "/images/member-sample-d.png",
    //     owner: "MUSEION",
    //     title: "Project 9",
    //     description: "Dosi onboarding",
    // },
];
