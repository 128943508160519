import React, { useEffect, useRef, useState } from "react";
import {
    BackgroundImage,
    FolderButton,
    MainLogoButtonWrapper,
    NavigationButton,
} from "@components";
import { Link } from "react-router-dom";
import { CCOMMON_NAVIGATION_BUTTON_DATASOURCE } from "@constants";
import { debounce } from "lodash";
import { getDevice } from "@utils/screen";
import { getCurrentPath } from "@utils/router";
import { IComponentProp } from "@components/types";
import { twMerge } from "tailwind-merge";
import { EDEVICE_TYPE } from "@enums";

const MobileNavigationEntryButton: React.FC<
    IComponentProp & {
        setActive: React.Dispatch<React.SetStateAction<boolean>>;
    }
> = ({ className, children, setActive }) => {
    const classNames = twMerge(
        "text-white text-[1.2rem] flex flex-col-reverse items-center mb-[4dvh]",
        className,
    );
    return (
        <button
            type="button"
            className={classNames}
            onClick={() => setActive(prev => !prev)}
        >
            {children}
        </button>
    );
};
export const NavigationButtonWrapper: React.FC<{
    initialized: boolean;
}> = ({ initialized }) => {
    const mobileNavSrc = "/images/nav-scrolling.svg";
    const device = getDevice();
    const [active, setActive] = useState(false);
    const [hoverChildId, setHoverChildId] = useState<number | null>(null);
    const [, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);
    const handleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, 500);
    const handleMouseEnter = (childId: number | null) => {
        setHoverChildId(childId);
    };
    useEffect(() => {
        if (device !== EDEVICE_TYPE.DESKTOP) {
            if (active) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "unset";
            }
        }

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [active, device]);
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            className={`flex flex-row-reverse justify-center absolute left-0 bottom-0 z-20 w-full ${initialized ? "mobile:visible tablet:visible" : "mobile:hidden tablet:hidden"}`}
        >
            {device === EDEVICE_TYPE.DESKTOP ? (
                <div
                    className="flex justify-between items-baseline"
                    ref={wrapperRef}
                >
                    {CCOMMON_NAVIGATION_BUTTON_DATASOURCE.map((nav, index) =>
                        nav.to ? (
                            <Link to={nav.to} key={index}>
                                <NavigationButton
                                    setHoveredId={handleMouseEnter}
                                    isHovered={hoverChildId === index}
                                    id={index}
                                    title={nav.title}
                                    description={nav.description}
                                    width={
                                        window.innerWidth /
                                        CCOMMON_NAVIGATION_BUTTON_DATASOURCE.length
                                    }
                                />
                            </Link>
                        ) : (
                            <NavigationButton
                                setHoveredId={handleMouseEnter}
                                isHovered={false}
                                key={index}
                                id={index}
                                title={nav.title}
                                description={nav.description}
                                width={
                                    window.innerWidth /
                                    CCOMMON_NAVIGATION_BUTTON_DATASOURCE.length
                                }
                            />
                        ),
                    )}
                </div>
            ) : (
                <div>
                    {!active ? (
                        <MobileNavigationEntryButton setActive={setActive}>
                            <div className="flex flex-col justify-center items-center animate-bounce">
                                <img src={mobileNavSrc} alt={mobileNavSrc} />
                            </div>
                            <span className="mb-3">{getCurrentPath()}</span>
                        </MobileNavigationEntryButton>
                    ) : (
                        <BackgroundImage
                            src="/images/bg-grid.png"
                            className="animate-verticalTranslateBottomToTop h-dvh w-dvw"
                        >
                            <MainLogoButtonWrapper />
                            <MobileNavigationEntryButton
                                setActive={setActive}
                                className="h-[96dvh]"
                            >
                                <div className="flex flex-col justify-center items-center w-full">
                                    <img
                                        className="rotate-180"
                                        src={mobileNavSrc}
                                        alt={mobileNavSrc}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-end w-[95%] h-[60dvh] border-r-4 border-white gap-10 mb-10">
                                    {CCOMMON_NAVIGATION_BUTTON_DATASOURCE.filter(
                                        ({ to }) => !!to,
                                    ).map((nav, index) => (
                                        <FolderButton
                                            key={index}
                                            id={index}
                                            title={nav.title}
                                            src="/images/folder-button-mobile.png"
                                            setHoveredId={handleMouseEnter}
                                            isHovered={hoverChildId === index}
                                            width={window.innerWidth}
                                            to={nav.to!}
                                        />
                                    ))}
                                </div>
                            </MobileNavigationEntryButton>
                        </BackgroundImage>
                    )}
                </div>
            )}
        </div>
    );
};
