import React from "react";
import { IImageComponentProp } from "@components/types/image";
import { twMerge } from "tailwind-merge";

export const MemberDisplayBox: React.FC<
    IImageComponentProp & { title: string; src: string }
> = ({ className, src, title }) => {
    const classNames = twMerge(
        "text-white flex mobile:flex-col tablet:flex-col desktop:flex-col-reverse",
        className,
    );
    return (
        <div className={classNames}>
            <div className="desktop:self-center mobile:self-start tablet:self-start desktop:mt-[30%] desktop:text-2xl">
                {title}
            </div>
            <img
                className="desktop:self-center mobile:self-end tablet:self-end mobile:ml-3 tablet:ml-3 desktop:w-28 mobile:h-[5.2dvh] tablet:h-[5.2dvh]"
                src={src}
                alt={src}
            />
        </div>
    );
};
