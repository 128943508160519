import { INavigationButtonAttributes } from "@components/types/button";
import { Routes as RouteConfig } from "@routes";

// !! DESKTOP !!
export const CLAYOUT_HEIGHT_CONTENT_DESKTOP = "desktop:h-[94.2vh]"; // CLAYOUT_HEIGHT_CONTENT_DESKTOP = 100 - CLAYOUT_TOP_MARGIN_OFFSET_DESKTOP - Additional Offset
export const CLAYOUT_HEIGHT_CONTENT_MOBILE = "mobile:h-[93.8lvh]"; // CLAYOUT_HEIGHT_CONTENT_MOBILE = 100 - CLAYOUT_TOP_MARGIN_OFFSET_MOBILE - Additional Offset
export const CLAYOUT_LEFT_MARGIN_CONTENT_DESKTOP = "desktop:ml-8";
export const CLAYOUT_TOP_MARGIN_OFFSET_DESKTOP = `desktop:mt-[5.6vh]`;
export const CLAYOUT_LEFT_MARGIN_OFFSET_DESKTOP = "desktop:ml-20";
export const CBUTTON_WIDTH_DESKTOP = "desktop:w-[70px]";

// !! MOBILE !!
export const CLAYOUT_TOP_MARGIN_OFFSET_MOBILE = `mobile:top-[6dvh]`;
export const CLAYOUT_LEFT_MARGIN_OFFSET_MOBILE = "mobile:left-[2.35rem]";
export const CBUTTON_WIDTH_MOBILE = "mobile:w-[2.8rem]";

// !! TABLET !!
export const CLAYOUT_TOP_MARGIN_OFFSET_TABLET = `tablet:top-[6dvh]`;
export const CLAYOUT_LEFT_MARGIN_OFFSET_TABLET = "tablet:left-[2.35rem]";
export const CBUTTON_WIDTH_TABLET = "tablet:w-[2.8rem]";

export const CLAYOUT_MAIN_LOGO_BUTTON_OFFSET = {
    MOBILE: [
        "mobile:absolute",
        CLAYOUT_TOP_MARGIN_OFFSET_MOBILE,
        CLAYOUT_LEFT_MARGIN_OFFSET_MOBILE,
        CBUTTON_WIDTH_MOBILE,
    ].join(" "),
    TABLET: [
        "tablet:absolute",
        CLAYOUT_TOP_MARGIN_OFFSET_TABLET,
        CLAYOUT_LEFT_MARGIN_OFFSET_TABLET,
        CBUTTON_WIDTH_TABLET,
    ].join(" "),
    DESKTOP: [
        "desktop:relative",
        "desktop:shrink-0",
        CLAYOUT_TOP_MARGIN_OFFSET_DESKTOP,
        CLAYOUT_LEFT_MARGIN_OFFSET_DESKTOP,
        CBUTTON_WIDTH_DESKTOP,
    ].join(" "),
};

export const CCOMMON_NAVIGATION_BUTTON_DATASOURCE: INavigationButtonAttributes[] =
    [
        {
            title: "",
            description: "",
        },
        {
            title: "ABOUT",
            description: "About Museion",
            to: RouteConfig.about,
        },
        {
            title: "TEAM",
            description: "About Team",
            to: RouteConfig.team,
        },
        {
            title: "PROJECT",
            description: "About Project",
            to: RouteConfig.project,
        },
        {
            title: "COMMUNITY",
            description: "About Community",
            to: RouteConfig.community,
        },
        {
            title: "",
            description: "",
        },
    ];
