import React from "react";
import { IImageComponentProp } from "@components/types/image";
import { BackgroundImage, NavigationButtonWrapper } from "@components";
import { twMerge } from "tailwind-merge";
import { getCurrentPath } from "@utils/router";

export const Page: React.FC<
    IImageComponentProp & {
        direction: "row" | "column";
    } & Partial<{ initialized: boolean }>
> = ({ className, src, children, color, direction, initialized = true }) => {
    const classNames = twMerge("relative", className);
    return (
        <BackgroundImage src={src} color={color}>
            <div className={classNames}>
                <div
                    className={`flex ${direction === "row" ? "desktop:flex-row" : "desktop:flex-col"} mobile:flex-col`}
                >
                    {children}
                </div>
                {getCurrentPath(false) !== "about" && (
                    <NavigationButtonWrapper initialized={initialized} />
                )}
            </div>
        </BackgroundImage>
    );
};
